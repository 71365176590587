<template>
  <BaseDetailSimple
    :leave-guard="leaveGuard"
    :input-attributes="inputAttributes"
    :query-values="queryValues"
    :name-map="nameMap"
    entity-type="crossForm"
    locale-section="pages.crossForms"
    field-section="crossforms"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "CrossFormDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        aspect
        form
      `,
      inputAttributes: {
        form: { type: "number", required: true },
        aspect: { required: true }
      }
    };
  },
  methods: {
    nameMap(values) {
      return values.aspect && values.form && values.aspect + " " + values.form;
    }
  }
};
</script>
